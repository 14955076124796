<template>
  <div class="scoreboard">
    <header class="header">比分直播</header>
    <main class="score-display">
      <div class="team">
        <h2>队伍 A</h2>
        <div class="score">{{ team1Score }}</div>
      </div>
      <div class="divider">VS</div>
      <div class="team">
        <h2>队伍 B</h2>
        <div class="score">{{ team2Score }}</div>
      </div>
    </main>
    <footer class="footer">
      <p>更新时间：{{ lastUpdated }}</p>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ws: null, // WebSocket 实例
      team1Score: 0,
      team2Score: 0,
      lastUpdated: "--:--:--",
    };
  },
  methods: {
    connectWebSocket() {
      const websocketUrl = process.env.VUE_APP_WEBSOCKET_URL; // 使用环境变量
      this.ws = new WebSocket(websocketUrl);

      this.ws.onopen = () => {
        console.log("WebSocket 连接成功!");
      };

      this.ws.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data.type === "live_score" && data.body) {
            const scores = data.body.match(/队伍1:(\d+) 队伍2:(\d+)/);
            if (scores) {
              this.team1Score = parseInt(scores[1], 10);
              this.team2Score = parseInt(scores[2], 10);
              this.lastUpdated = new Date().toLocaleTimeString();
            }
          }
        } catch (err) {
          console.error("消息解析失败", err);
        }
      };

      this.ws.onerror = (error) => {
        console.error("WebSocket 错误", error);
      };

      this.ws.onclose = () => {
        console.log("WebSocket 连接关闭");
        // 尝试重新连接
        setTimeout(this.connectWebSocket, 5000);
      };
    },
  },
  mounted() {
    this.connectWebSocket();
  },
};
</script>

<style scoped>
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #333;
  color: #fff;
}

.scoreboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  text-align: center;
  overflow: hidden;
}

.header {
  font-size: 2rem;
  font-weight: bold;
  padding: 20px;
  background-color: #222;
  width: 100%;
  text-align: center;
}

.score-display {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  flex-grow: 1;
}

.team {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.score {
  font-size: 15rem;
  font-weight: bold;
  margin-top: 20px;
  color:#FF0000;
}

.divider {
  font-size: 3rem;
  font-weight: bold;
  padding: 0 20px;
}

.footer {
  font-size: 1.2rem;
  padding: 10px 0;
  background-color: #222;
  width: 100%;
}
</style>
